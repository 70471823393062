$(document).on('turbolinks:load',function() {
  "use strict";











  /**
   * Testimonials slider
   */
  new Swiper('.testimonials-slider', {
    speed: 600,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });

  /**
   * Testimonials slider
   */
  new Swiper('.section7-slider', {
      slidesPerView: 2,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 1.25,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 1.25,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 15
        }
      }
  });

  /**
   * Testimonials RenouveauTV slider
   */
  new Swiper('.renouveautv-slider', {
      slidesPerView: 6,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.renouveautv-slider');
        },
        resize: function () {
          checkArrow('.renouveautv-slider');
        }
      }
  });


  /**
   * Testimonials RenouveauTV slider
   */
  new Swiper('.renouveautv-slider2', {
      slidesPerView: 6,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.renouveautv-slider2');
        },
        resize: function () {
          checkArrow('.renouveautv-slider2');
        }
      }
  });


  /**
   * Testimonials RMC TV slider
   */
  new Swiper('.rmctv-slider2', {
      slidesPerView: 6,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.rmctv-slider2');
        },
        resize: function () {
          checkArrow('.rmctv-slider2');
        }
      }
  });



/**
   * Testimonials slider
   */
  new Swiper('.section1-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.section1-slider');
        },
        resize: function () {
          checkArrow('.section1-slider');
        }
      }
  });

  /**
   * Emissions RenouveauTV section 1 slider
   */
  new Swiper('.renouveautv-section1-slider', {
      slidesPerView: 6,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.renouveautv-section1-slider');
        },
        resize: function () {
          checkArrow('.renouveautv-section1-slider');
        }
      }
  });

  /**
   * Emissions RMC TV section 1 slider
   */
  new Swiper('.rmctv-section1-slider', {
      slidesPerView: 6,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.rmctv-section1-slider');
        },
        resize: function () {
          checkArrow('.rmctv-section1-slider');
        }
      }
  });

  /**
   * Testimonials slider
   */
  new Swiper('.section2-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      }
  });







  /**
   * Emissions RenouveauTV section2 slider
   */
  new Swiper('.renouveautv-section2-slider', {
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        }
      }
  });


  /**
   * Emissions RMC TV section2 slider
   */
  new Swiper('.rmctv-section2-slider', {
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        }
      }
  });




  /**
   * Testimonials slider
   */
  new Swiper('.section3-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next-3",
        prevEl: ".swiper-button-prev-3",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.section3-slider');
        },
        resize: function () {
          checkArrow('.section3-slider');
        }
      }
      
  });




  /**
   * Emissions RenouveauTV section 3 slider
   */
  new Swiper('.renouveautv-section3-slider', {
      slidesPerView: 6,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next-3",
        prevEl: ".swiper-button-prev-3",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.renouveautv-section3-slider');
        },
        resize: function () {
          checkArrow('.renouveautv-section3-slider');
        }
      }
      
  });



   /**
   * Emissions RMC TV section 3 slider
   */
  new Swiper('.rmctv-section3-slider', {
      slidesPerView: 6,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next-3",
        prevEl: ".swiper-button-prev-3",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.rmctv-section3-slider');
        },
        resize: function () {
          checkArrow('.rmctv-section3-slider');
        }
      }
      
  });

  /**
   * Testimonials slider
   */
  new Swiper('.section4-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      }
  });


   /**
   * Emissions RenouveauTV section 4 slider
   */
  new Swiper('.renouveautv-section4-slider', {
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        }
      }
  });

   /**
   * Emissions RMC TV section 4 slider
   */
  new Swiper('.rmctv-section4-slider', {
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        }
      }
  });


  /**
   * Testimonials slider
   */
  new Swiper('.section5-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.section5-slider');
        },
        resize: function () {
          checkArrow('.section5-slider');
        }
      }
      
  });


  /**
   * Emissions RenouveauTV section 5 slider
   */
  new Swiper('.renouveautv-section5-slider', {
      slidesPerView: 6,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.renouveautv-section5-slider');
        },
        resize: function () {
          checkArrow('.renouveautv-section5-slider');
        }
      }
      
  });

  /**
   * Emissions RMC TV section 5 slider
   */
  new Swiper('.rmctv-section5-slider', {
      slidesPerView: 6,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.rmctv-section5-slider');
        },
        resize: function () {
          checkArrow('.rmctv-section5-slider');
        }
      }
      
  });

  /**
   * rmctv-slider slider
   */
  new Swiper('.rmctv-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.rmctv-slider');
        },
        resize: function () {
          checkArrow('.rmctv-slider');
        }
      }
      
  });

  /**
   * actualite-sociate-slider slider
   */
  new Swiper('.actualite-societe-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      }
  });

  /**
   * Testimonials slider
   */
  new Swiper('.section6-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 1.75,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      }

  });



  /**
   * Emissions RenouveauTV section 6 slider
   */
  new Swiper('.renouveautv-section6-slider', {
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 1.75,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        }
      }

  });



  /**
   * Emissions RMC TV section 6 slider
   */
  new Swiper('.rmctv-section6-slider', {
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 1.75,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        }
      }

  });

  

  /**
   * Testimonials slider
   */
  new Swiper('.section8-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 1.25,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 1.25,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      }
  });

  /**
   * Emissions RMC TV section 8 slider
   */
  new Swiper('.rmctv-section8-slider', {
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        }
      }
  });

  /**
   * Events slider
   */
  new Swiper('.events-slider', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.events-slider');
        },
        resize: function () {
          checkArrow('.events-slider');
        }
      }
  });

  /**
   * Campaigns slider
   */
  new Swiper('.campaigns-slider', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.campaigns-slider');
        },
        resize: function () {
          checkArrow('.campaigns-slider');
        }
      }
  });

   /**
   * Campaigns slider
   */
  new Swiper('.campaigns-slider2', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.campaigns-slider2');
        },
        resize: function () {
          checkArrow('.campaigns-slider2');
        }
      }
  });



   /**
   * Posts slider
   */
  new Swiper('.posts-slider', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1920: {
          slidesPerView: 5,
          spaceBetween: 15
        },
      },
      on: {
        init: function() {
          checkArrow('.posts-slider2');
        },
        resize: function () {
          checkArrow('.posts-slider2');
        }
      }
  });


  /**
   * Parutions slider
   */
  new Swiper('.parutions-slider2', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.parutions-slider2');
        },
        resize: function () {
          checkArrow('.parutions-slider2');
        }
      }
  });

  /* Music layouts */

  /**
   * Music albums slider
   */
  new Swiper('.music-albums-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.music-albums-slider');
        },
        resize: function () {
          checkArrow('.music-albums-slider');
        }
      }
  });

  /**
   * Singles slider
   */
  new Swiper('.singles-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      }
  });


  /**
   * Music sounds slider
   */
  new Swiper('.sounds-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.sounds-slider');
        },
        resize: function () {
          checkArrow('.sounds-slider');
        }
      }
  });


  /**
   * LP slider
   */
  new Swiper('.lp-musics-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      }
  });


  /**
   * EP slider
   */
  new Swiper('.ep-musics-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.ep-musics-slider');
        },
        resize: function () {
          checkArrow('.ep-musics-slider');
        }
      }
  });



  /* Movies layouts */

  /**
   * Series divertissements slider
   */
  new Swiper('.series-divertissements-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.series-divertissements-slider');
        },
        resize: function () {
          checkArrow('.series-divertissements-slider');
        }
      }
  });

  /**
   * Series dramatiques slider
   */
  new Swiper('.series-dramatiques-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      }
  });


  /**
   * Documentaires slider
   */
  new Swiper('.documentaires-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.documentaires-slider');
        },
        resize: function () {
          checkArrow('.documentaires-slider');
        }
      }
  });


  /**
   * Enquettes slider
   */
  new Swiper('.enquettes-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.enquettes-slider');
        },
        resize: function () {
          checkArrow('.enquettes-slider');
        }
      }
  });


  /**
   * Telerealites slider
   */
  new Swiper('.telerealites-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.telerealites-slider');
        },
        resize: function () {
          checkArrow('.telerealites-slider');
        }
      }
  });








  /** Newspapers layouts */


  /**
   * Parutions slider
   */
  new Swiper('.parutions-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.parutions-slider');
        },
        resize: function () {
          checkArrow('.parutions-slider');
        }
      }
  });

  /**
   * Series dramatiques slider
   */
  new Swiper('.newspapers-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.newspapers-slider');
        },
        resize: function () {
          checkArrow('.neswpapers-slider');
        }
      }
  });




 


  /**
   * Porfolio isotope and filter
   */
  window.addEventListener('load', () => {
    let portfolioContainer = select('.portfolio-container');
    if (portfolioContainer) {
      let portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: '.portfolio-item',
        layoutMode: 'fitRows'
      });

      let portfolioFilters = select('#portfolio-flters li', true);

      on('click', '#portfolio-flters li', function(e) {
        e.preventDefault();
        portfolioFilters.forEach(function(el) {
          el.classList.remove('filter-active');
        });
        this.classList.add('filter-active');

        portfolioIsotope.arrange({
          filter: this.getAttribute('data-filter')
        });
        portfolioIsotope.on('arrangeComplete', function() {
          AOS.refresh()
        });
      }, true);
    }

  });

  /**
   * Initiate portfolio lightbox 
   */
  const portfolioLightbox = GLightbox({
    selector: '.portfolio-lightbox'
  });

  /**
   * Portfolio details slider
   */
  new Swiper('.portfolio-details-slider', {
    speed: 400,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });



  function checkArrow(section) {
 
    
    var swiperPrev = document.querySelector(`${section} .swiper-button-prev`);
    var swiperNext = document.querySelector(`${section} .swiper-button-next`);
    
    if (swiperPrev !== null && swiperNext !== null){
      if ( window.innerWidth < 1024  ) {
        //console.log('Success', window.innerWidth);
        //swiperPrev.style.display = 'block';
        //swiperNext.style.display = 'block';
        swiperPrev.style.display = 'none';
        swiperNext.style.display = 'none';
      } else {
        //swiperPrev.style.display = 'none';
        //swiperNext.style.display = 'none';
        swiperPrev.style.display = 'block';
        swiperNext.style.display = 'block';
      }
    }
  }









});